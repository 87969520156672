$white: #ffffff;
$black: #000000;
$black-2: #323232;

$grey-1: #f5f5f5;
$grey-2: #ebebeb;
$grey-3: #dcdcdc;
$grey-4: #b9b9b9;
$grey-5: #969696;
$grey-6: #737373;
$grey-7: #505050;
$grey-8: #2e2e2e;
$text-grey: #323232;

$msg-warning: #c83727;

$primary-petrol-blue: #007398;
$primary-orange: #e9711c;
$primary-lt-blue: #3679e0;

$secondary-orange: #fff0e4;
$secondary-pale-blue: #cde4ff;
