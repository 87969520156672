@import './colors.scss';

.upload-progress-bar {
  background-color: $primary-petrol-blue;
  height: 4px;
}

.line {
  background-color: $grey-7;
  height: 2px;
}

.collection-table {
  height: 300px;
  overflow: auto;
  margin-top: 25px;

  thead th {
    position: sticky;
    top: 0;
    background: $white;
    border-bottom: 1px solid $grey-3;
  }
  tbody td {
    border-top: 0px;
    border-bottom: 1px solid $grey-3;
    vertical-align: middle;
  }
}

.modal-body {
  margin-top: 2%;
  margin-bottom: 2%;
}

#modal-info-attention-box {
  display: flex;
  flex-flow: row wrap;
}
